import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import io from 'socket.io-client';
import HTTPClientCore, { IRequestConfig } from '../HTTPClient';
import { CANCEL } from 'redux-saga';
import {
  IClientModel,
  IConfirmForgotPasswordRequest,
  IConfirmForgotPasswordResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse,
  ICreateClientRequest,
  ICreatePositionRequest,
  ICreateShiftRequest,
  ICreateSiteRequest,
  IFilter,
  IForgotPasswordRequest,
  IForgotPasswordResponse,
  ILoginRequest,
  IPositionModel,
  IRegisterRequest,
  IRegisterResponse,
  IShiftModel,
  ISiteModel,
  IUserModel,
  IUserUpdatePasswordRequest,
  IUserUpdateProfileRequest,
  IUserUpdateResponse,
  IWhere,
  IdsArray,
  ICreateEmployee,
  IEmployeeModel,
  ICreateDepartmentRequest,
  IDepartmentModel,
  ICreateLogtimeRequest,
  ILogtimeModel,
  ICompleteCreationRequest,
  ICompleteCreationResponse,
  ICreateStaffingProviderRequest,
  IStaffingProviderModel,
  ICreateSkillRequest,
  ISkillModel,
  ICreateEmployeeSkillRequest,
  IEmployeeSkillModel,
  IWorkdaysModel,
  ILoggedInModel,
  MoveToDepartmentRequestType,
  IIssueTypeModel,
  ICreateIssueType,
  IIssueModel,
  ICreateIssue,
  ICreatePointType,
  IPointTypeModel,
  IPointModel,
  ICreatePoint,
  PayboardDetails,
  PayboardDetailsRequest,
  ITurnoverAddedModel,
  ITurnoverLostModel,
  ITurnoverActiveModel,
  ICreateMetatagRequest,
  IMetatagModel,
  ICreateStandardRequest,
  IStandardModel,
  IStandardWithMeta,
  IUserPolicy,
  INotificationModel,
  ICreateTerminationReasonRequest,
  ITerminationReasonModel,
  ITurnoverTerminatedModel,
  INotificationsPreferences,
  IRecruitingDashboardSummaryModel,
  IRecruitingDashboardDetailsModel,
  IRecruitingDashboardDetailsRequest,
  IDowntimeChartPointData,
  ICreateOpenPosition,
  IOpenPositionModel,
  IUpdateOpenPosition,
  ICreateApplicant,
  IApplicantModel,
  IUpdateApplicant,
  IDailyHoursReportItem,
  ICountResponse,
  ISiteSettingModel,
  ISetSiteSettingRequest,
  ListWithSummary,
  IPayboardModel,
  PayboardWeek,
  TurnoverType,
  ICreatePolicyRequest,
  IPolicyModel,
  IUpdatePolicy,
  ILogtimeSupervisorViewModel,
  ICreateBom,
  IBomModel,
  ICreateProduction,
  IProductionModel,
  IPayboardCostByDepartment,
  IPayboardCostByDay,
  IPayboardCostByDayAndDepartment,
  IEmployeesActiveButNotPresentModel,
  IUpdatePositionRequest,
  IEmployeesTerminatedModel,
  Resource,
  ICreateSiteImportingSettings,
  ISiteImportingSettingsModel,
  ICreatePricingSingleRequest,
  IPricingModel,
  IPricingWithMeta,
  ClientKeys,
  ICreatePricingRangeApiRequest,
  INotificationData,
  IUpdatePricingRangeApiRequest,
  ICreateQuestionCategoryRequest,
  IQuestionCategoryModel,
  ICreateQuestionRequest,
  IQuestionModel,
  IUpdateQuestionCategoryRequest,
  IUpdateQuestionRequest,
  ICreateSiteTaktExportingSettings,
  ISiteTaktExportingSettingsModel,
  ICreateCostPlusSettings,
  ICostPlusSettingsModel,
  CostPlusTypes,
  ICreateSiteBillingDetails,
  ISiteBillingDetailsModel,
  ICreateNgroupBillingDetails,
  INgroupBillingDetailsModel,
  IAdditionalFee,
  IAdditionalFeeModel,
  IAdditionalFeeCategory,
  IAdditionalFeeCategoryModel,
  IFilledAssociatesModel,
  ICreateWeekBasedInvoice,
  IInvoiceModelWithRelations,
  IUpdateInvoice,
  IExportInvoice,
  IInvoiceDetails,
  IArea,
  PayboardSummaryByWorkWeekData,
  ICreateIssueInIssueTracker,
  IIssueTrackerIssueModel,
  IIssueTrackerIssueResponse,
  IUpdateIssueInIssueTracker,
  IPunchIntersectionResponse,
  IPunchIntersectionRequest,
  IIssueTrackerPrioritiesResponse,
  ICreateCpuBasedBaseline,
  ICpuBasedBaselineModel,
  ICpuBasedBaselineWithRelations,
  ICreateAreaBasedBaseline,
  IAreaBasedBaselineModel,
  IAreaBasedBaselineWithRelations,
  IManualProductionSettings,
  IHookyFolksModel,
  IHistoricalHookyFolksModel,
  DataWithCount,
  AnyObject,
  PayboardSummaryByWorkWeekAndSupervisorData,
  ICreateOtherCosts,
  IOtherCostsModel,
  IPayboardDetailedModel,
  IExportDailyPunches,
  IExportRangePunches,
  IRemovePoint,
  ICreateMonthBasedInvoiceRequest,
  IUpdateMonthInvoice,
  IInvoiceMonthDetails,
  ISetArchiveStatusArgs,
  ICreateEmployeeSkillAttachment,
  IEmployeeSkillAttachmentModel,
  IRoleModel,
  ICreateRoleRequest,
  ICreateUserWithAttributesRequest,
  IUpdateUserWithAttributesRequest,
  ICreateBaselineRequest,
  IBaselineModel,
  IBaselineWithMeta,
  IPageSettingModel,
  ICreatePolicyByPageRequest,
  IPricingSettings,
  ICreateOptiturnCredentials,
  IOptiturnCredentialsModel,
  IQSJWT,
  IKpiSettings,
  IKpi,
  ICreatePolicyByOperationRequest,
  KpiBasis,
  IUpdateSite,
  ISendSmsToEmployees,
  PayboardDay,
  ICreateEmployeeWithoutPaymentInfo,
  IUpdateAreaRequestData,
  IDeleteAreaRequestData,
  IIncentivePriceModel,
  IIncentivePriceUpdateRequest,
  IIncentivePricingSettings,
  ShiftAutomaticPointsSettings,
  IAutomaticPointsRejectReasonModel,
  ICreateAutomaticPointsRejectReasonRequest,
  IPointCollectionModel,
  ICreateQuestionCategoryTypeRequest,
  IQuestionCategoryTypeModel,
  IFavoriteModel,
  IExcessiveHoursModel,
} from '../../types';
import { getUserObjectFromStorage } from '../user';
import { IExportParams } from '../../types';
import { addProcessStatus } from '../../actions';
import { dispatchAction } from '../helpers/actionsDispatcher';
import { IVideoItem } from '../../types/guide';
import {
  IChecklistModel,
  IChecklistModelWithRelations,
  IChecklistTaskModel,
  ICreateChecklistData,
  ICreateChecklistTaskData,
  IUpdateChecklistData,
  IUpdateChecklistTaskData,
} from 'src/modules/types/checklist';
import { ICostPlusWeeklyModel } from '../../types/CostPlusWeeklyTypes';
import {
  IProductionDailyUphBase,
  IProductionDailyUphBySiteAndEmployeeModel,
  IProductionDailyUphBySiteAndMetatagModel,
  IProductionDailyUphBySiteModel,
  IProductionDailyUphBySiteSkuAndEmployeeModel,
  IProductionWeeklyUphBySiteAndEmployeeModel,
  IProductionWeeklyUphBySiteAndSkuModel,
  IProductionWeeklyUphBySiteModel,
  IProductionWeeklyUphBySiteSkuAndEmployeeModel,
} from '../../types/productionUphReports';
import { ISyncImporting } from '../../types/cardinal-health';
import {
  IProductionWeeklyCpuDetailedModel,
  IProductionWeeklyCpuModel,
} from '../../types/productionCpuReports';
import { IExceptionsReportDataResponse } from 'src/modules/types/reports';
import { IQuestionEmployeeModel } from 'src/modules/types/question-employee';
import {
  IWikiPage,
  IWikiPaginationCursorDirection,
  UpdateWikiForm,
} from '../../types/wiki-setup';
import { WikiPageTypeEnum } from '../../../config/wiki-pages-types';
import { IGrossMargin, IWeeklyGrossMargin } from '../../types/gross-margin';
import {
  IFinancialTrackerCreate,
  IFinancialTrackerModel,
  IFinancialTrackerUpdate,
} from '../../types/financial-tracker';
import { apiEndpoints } from 'src/config/apiEndpoints';
import { paths } from '../../../config';
import { IIncentivePaymentSettingsResponseData } from 'src/modules/types/incentive-payment-settings';
import {
  IIncentiveCreateRequest,
  IUpdateTotalPayment,
} from '../../types/incentive';
import {
  IIpWhitelistCreateRequestData,
  IIpWhitelistModel,
} from 'src/modules/types/ip_whitelist';

const VALIDATION_FAILED = 'VALIDATION_FAILED';
const REQUEST_CANCELED = 'REQUEST_CANCELED';
const REQUEST_CANCELED_CODE = 'ERR_CANCELED';
/**
 * HTTPClient class will be used to make requests to the server
 */
class HTTPClient extends HTTPClientCore {
  /**
   * Overridden method adds CancelToken symbol, that allow redux-saga'
   * "takeLatest" function to cancel any requests automatically.
   */
  public makeRequest(config: IRequestConfig): Promise<any> {
    const source = axios.CancelToken.source();

    // get token from localStorage
    const { auth: { token = '' } = {} } = getUserObjectFromStorage();

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    const request = super.makeRequest({
      headers: {
        'Content-Type': 'application/json',
        ...config.headers,
      },
      ...config,
      params: {
        ...config.params,
      },
      cancelToken: source.token,
    });

    (request as any)[CANCEL] = () => source.cancel(REQUEST_CANCELED);

    return request;
  }

  public getFile(config: IRequestConfig): Promise<any> {
    const { auth: { token = '' } = {} } = getUserObjectFromStorage();

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return super.getFile({ ...config });
  }
}

interface IErrorDetail {
  code: string;
  message: string;
}

export interface IErrorDetails {
  [key: string]: IErrorDetail;
}

type IErrorData = {
  error: {
    message: string;
    errorCode: number;
    code?: string;
    apiCode?: string;
    apiMessage?: string;
    details?: IErrorDetails;
  };
};
const createErrorWithNotification = (
  message: string,
  localizationKeys: string[],
) => {
  localizationKeys.forEach((key) => {
    dispatchAction(
      addProcessStatus({
        variant: 'error',
        message: key,
      }),
    );
  });

  return Error(message);
};

/**
 * Create an instance of HTTPClient to be used to make requests to the server
 */
const APIClient = new HTTPClient({
  baseURL: process.env.REACT_APP_API_URL || '',
  withCredentials: false,
  // TODO: (refactoring) i don't know, maybe it's better to use other array format
  // paramsSerializer: params => qs.stringify(params, { arrayFormat: 'indices', encode: true }),
  onCatchNetworkError: async (err) => {
    // Do nothing if request canceled
    if (
      err.message === REQUEST_CANCELED ||
      err.code === REQUEST_CANCELED_CODE
    ) {
      return;
    }

    if (!err.response) {
      throw createErrorWithNotification(err.message, [
        'error.api.internal_server_error',
      ]);
    }

    if (err.response.data instanceof Blob) {
      err.response.data = await new Response(err.response.data).json();
    }

    const errorData = (err as AxiosError<IErrorData>)?.response?.data.error;

    // Handle form validation errors
    if (errorData?.code === VALIDATION_FAILED && errorData.details) {
      // Replace whitespace with underscore and make message lower case
      const localizedErrors = Object.values(errorData.details).map(
        ({ message }) => {
          const key = message.replace(/\s+/g, '_').toLowerCase();

          return `error.api.validation.${key}`;
        },
      );

      throw createErrorWithNotification(err.message, localizedErrors);
    }

    // apiMessage fields contains keys for server errors localization
    if (!errorData?.apiMessage) {
      // We need to know for which errors localization is not implemented yet
      // and provide some OK user experience in the same time, so
      // currently for this case user will see general server error and
      // in development mode we will see appropriate console error
      process.env.NODE_ENV === 'development' &&
        console.error(
          'Localization is not implement for the error:\n',
          err.response,
        );

      throw createErrorWithNotification(err.message, [
        'error.api.internal_server_error',
      ]);
    }

    throw createErrorWithNotification(err.message, [errorData.apiMessage]);
  },
});

export const Api = {
  Auth: {
    // authenticate a user
    login: (params: ILoginRequest): Promise<IUserModel> =>
      APIClient.post(`/auth/login`, params),
    // send an email to restore a password
    forgotPassword: (
      params: IForgotPasswordRequest,
    ): Promise<IForgotPasswordResponse> =>
      APIClient.post(`/auth/forgot-password`, params),
    // confirm restoring password
    confirmForgotPassword: (
      params: IConfirmForgotPasswordRequest,
    ): Promise<IConfirmForgotPasswordResponse> =>
      APIClient.post(`/auth/confirm-forgot-password`, params),
    // register a new user
    register: (params: IRegisterRequest): Promise<IRegisterResponse> =>
      APIClient.post(`/auth/register`, params),
    // confirm registration
    confirmRegistration: (
      params: IConfirmRegistrationRequest,
    ): Promise<IConfirmRegistrationResponse> =>
      APIClient.post(`/auth/confirm-register`, params),
  },
  PageSettings: {
    get: (): Promise<IPageSettingModel> => APIClient.get(`/page-settings`),
    update: (data: IPageSettingModel): Promise<IPageSettingModel> =>
      APIClient.patch(`/page-settings`, data),
  },
  User: {
    create: (params: ICreateUserWithAttributesRequest): Promise<IUserModel> =>
      APIClient.post(`/users`, params),
    bulkUpdate: (params: IUpdateUserWithAttributesRequest[]): Promise<void> =>
      APIClient.patch(`/users`, params),
    // get current user's profile data
    me: (): Promise<IUserModel> => APIClient.get(`/users/me`),
    // get current user's policy data
    myPolicy: (): Promise<IUserPolicy> => APIClient.get(`/users/me/can-i-do`),
    // extend current user's session
    extendSession: (): Promise<IUserModel> =>
      APIClient.post(`/users/me/extend-session`),
    // update profile data
    profileUpdate: (
      params: IUserUpdateProfileRequest,
    ): Promise<IUserUpdateResponse> =>
      APIClient.patch(`/users/me/profile-update`, params),
    // update password
    passwordUpdate: (
      params: IUserUpdatePasswordRequest,
    ): Promise<IUserUpdateResponse> =>
      APIClient.patch(`/users/me/password-update`, params),
    // change notifications preferences
    notificationsPreferencesChange: (
      params: INotificationsPreferences,
    ): Promise<void> =>
      APIClient.patch(`/users/me/notifications-preferences`, params),
    // get user list
    list: (filters?: IFilter): Promise<IUserModel[]> =>
      APIClient.get(`/users`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/users/count`, filter),
    // delete users
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/users`, where),
    // reset password
    resetPassword: (ids: IdsArray): Promise<void> =>
      APIClient.patch(`/users/reset-password`, ids),
    // lock users
    lock: (ids: IdsArray): Promise<void> => APIClient.patch(`/users/lock`, ids),
    // unlock users
    unlock: (ids: IdsArray): Promise<void> =>
      APIClient.patch(`/users/unlock`, ids),
    // activate users
    activate: (ids: IdsArray): Promise<void> =>
      APIClient.patch(`/users/activate`, ids),
    // deactivate users
    deactivate: (ids: IdsArray): Promise<void> =>
      APIClient.patch(`/users/deactivate`, ids),
    // complete creation
    completeCreation: (
      params: ICompleteCreationRequest,
    ): Promise<ICompleteCreationResponse> =>
      APIClient.post(`/users/creation-complete`, params),
    // complete creation
    getPrefilledData: (token: string): Promise<ICompleteCreationResponse> =>
      APIClient.get(`/users/creation-complete/${token}`),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/users/export',
        params: exportData,
      }),
  },
  Position: {
    // create a new position
    create: (params: ICreatePositionRequest): Promise<IPositionModel> =>
      APIClient.post(`/positions`, params),
    // get position list
    list: (filters?: IFilter): Promise<IPositionModel[]> =>
      APIClient.get(`/positions`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/positions/count`, filter),
    // delete positions
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/positions`, where),
    // bulk update positions
    bulkUpdate: (positions: IUpdatePositionRequest[]): Promise<void> =>
      APIClient.patch(`/positions`, positions),
    updateDesiredEmployeesNumber: (
      positions: Pick<
        IUpdatePositionRequest,
        'id' | 'desiredEmployeesNumber'
      >[],
    ): Promise<void> =>
      APIClient.patch(`/positions/desired-employees-number`, positions),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/positions/export',
        params: exportData,
      }),
  },
  Shift: {
    // create a new shift
    create: (params: ICreateShiftRequest): Promise<IShiftModel> =>
      APIClient.post(`/shifts`, params),
    // get shift list
    list: (filters?: IFilter): Promise<IShiftModel[]> =>
      APIClient.get(`/shifts`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/shifts/count`, filter),
    // delete shifts
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/shifts`, where),
    // bulk update shifts
    bulkUpdate: (shifts: IShiftModel[]): Promise<void> =>
      APIClient.patch(`/shifts`, shifts),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/shifts/export',
        params: exportData,
      }),
  },
  ExcessiveHours: {
    list: (filters?: IFilter): Promise<ListWithSummary<IExcessiveHoursModel>> =>
      APIClient.get(`/payboard/excessive-hours-report`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/payboard/excessive-hours-report/count`, filter),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/payboard/excessive-hours-report/export',
        params: exportData,
      }),
  },
  QuestionCategoryType: {
    // create a new shift
    create: (
      params: ICreateQuestionCategoryTypeRequest,
    ): Promise<IQuestionCategoryTypeModel> =>
      APIClient.post(`/question-category-type`, params),
    // get shift list
    list: (filters?: IFilter): Promise<IQuestionCategoryTypeModel[]> =>
      APIClient.get(`/question-category-type`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/question-category-type/count`, filter),
    // delete shifts
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/question-category-type`, where),
    // bulk update shifts
    bulkUpdate: (items: IQuestionCategoryTypeModel[]): Promise<void> =>
      APIClient.patch(`/question-category-type`, items),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/question-category-type/export',
        params: exportData,
      }),
  },
  AutomaticPointsRejectReason: {
    // create a new automaticPointsRejectReason
    create: (
      params: ICreateAutomaticPointsRejectReasonRequest,
    ): Promise<IAutomaticPointsRejectReasonModel> =>
      APIClient.post(`/automatic-points-reject-reasons`, params),
    // get automaticPointsRejectReason list
    list: (filters?: IFilter): Promise<IAutomaticPointsRejectReasonModel[]> =>
      APIClient.get(`/automatic-points-reject-reasons`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/automatic-points-reject-reasons/count`, filter),
    // delete automaticPointsRejectReasons
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/automatic-points-reject-reasons`, where),
    // bulk update automaticPointsRejectReasons
    bulkUpdate: (
      automaticPointsRejectReasons: IAutomaticPointsRejectReasonModel[],
    ): Promise<void> =>
      APIClient.patch(
        `/automatic-points-reject-reasons`,
        automaticPointsRejectReasons,
      ),
  },
  Area: {
    getAreaList: (filters?: IFilter): Promise<IArea[]> =>
      APIClient.get('/areas', filters),
    getAllowedProperties: (siteId: number): Promise<string[]> =>
      APIClient.get(`/areas/get-allowed-properties/${siteId}`),
    updateArea: (data: IUpdateAreaRequestData): Promise<void> =>
      APIClient.patch('/area', data),
    createArea: (data: IArea): Promise<void> => APIClient.post('/area', data),
    deleteArea: (where: IDeleteAreaRequestData): Promise<void> =>
      APIClient.delete('/area', { where }),
  },
  Client: {
    // create a new client
    create: (params: ICreateClientRequest): Promise<IClientModel> =>
      APIClient.post(`/clients`, params),
    // get client list
    list: (filters?: IFilter): Promise<IClientModel[]> =>
      APIClient.get(`/clients`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/clients/count`, filter),
    // delete clients
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/clients`, where),
    // bulk update clients
    bulkUpdate: (clients: IClientModel[]): Promise<void> =>
      APIClient.patch(`/clients`, clients),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/clients/export',
        params: exportData,
      }),
  },
  Site: {
    // create a new site
    create: (params: ICreateSiteRequest): Promise<ISiteModel> =>
      APIClient.post(`/sites`, params),
    // get site list
    list: (filters?: IFilter): Promise<ISiteModel[]> =>
      APIClient.get(`/sites`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/sites/count`, filter),
    // delete sites
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/sites`, where),
    // bulk update sites
    bulkUpdate: (sites: ISiteModel[]): Promise<void> =>
      APIClient.patch(`/sites`, sites),
    bulkArchive: (sites: ISetArchiveStatusArgs[]): Promise<void> =>
      APIClient.patch(`/sites/archived`, sites),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/sites/export',
        params: exportData,
      }),
    updateDesiredEmployeesNumber: (
      positions: Pick<
        IUpdateSite,
        'id' | 'requiredAssociates' | 'absenteeism'
      >[],
    ): Promise<void> =>
      APIClient.patch(`/sites/required-employees-and-absenteeism`, positions),
  },
  SiteSetting: {
    getForCurrentUser: (): Promise<ISiteSettingModel> =>
      APIClient.get(`/site-settings/get-for-current-user`),
    // create a new siteSetting
    set: (params: ISetSiteSettingRequest): Promise<void> =>
      APIClient.post(`/site-settings/set`, params),
    // get siteSetting list
    all: (): Promise<ISiteSettingModel[]> => APIClient.get(`/site-settings`),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/site-settings/count`, filter),
    getForEdit: (
      clientId: number,
      siteId?: number,
    ): Promise<ISiteSettingModel> => {
      return APIClient.get(`/site-settings/get-for-edit`, {
        clientId,
        siteId,
      });
    },
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/site-settings/export',
        params: exportData,
      }),
  },
  Employee: {
    // create a new employee
    create: (params: ICreateEmployee): Promise<IEmployeeModel> => {
      return APIClient.post(`/employees`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    createWithoutPaymentInfo: (
      params: ICreateEmployeeWithoutPaymentInfo,
    ): Promise<IEmployeeModel> =>
      APIClient.post(`/employees/create-without-payment-info`, params),
    // get employee list
    list: (filters?: IFilter): Promise<IEmployeeModel[]> =>
      APIClient.get(`/employees`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/employees/count`, filter),
    // delete employees
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/employees`, where),
    // bulk update employees
    bulkUpdate: (employees: IEmployeeModel[]): Promise<void> =>
      APIClient.patch(`/employees`, employees, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    restore: (
      employee: IEmployeeModel & { deletedAt?: string | null },
    ): Promise<void> => APIClient.patch(`/employee/restore`, employee),
    // fetch active but not present employees list
    activeButNotPresentList: (
      filters?: IFilter,
    ): Promise<IEmployeesActiveButNotPresentModel[]> =>
      APIClient.get(`/employees/active-but-not-present`, filters),
    // fetch active but not present employees count
    activeButNotPresentCount: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/employees/active-but-not-present/count`, filter),
    terminatedList: (filter?: IFilter): Promise<IEmployeesTerminatedModel[]> =>
      APIClient.get(`/employees/terminated`, filter),
    terminatedCount: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/employees/terminated/count`, filter),
    hookyFolksList: (filter?: IFilter): Promise<IHookyFolksModel[]> =>
      APIClient.get(`/employees/hooky-folks`, filter),
    hookyFolksCount: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/employees/hooky-folks/count`, filter),
    exportHookyFolks: (exportData: IExportParams): Promise<number> =>
      APIClient.getFile({
        url: `/employees/hooky-folks/export`,
        params: exportData,
      }),
    historicalHookFolksList: (
      filter?: IFilter,
    ): Promise<IHistoricalHookyFolksModel[]> =>
      APIClient.get(`/employees/hooky-folks/historical`, filter),
    historicalHookFolksCount: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/employees/hooky-folks/historical/count`, filter),
    exportHistoricalHookyFolks: (exportData: IExportParams): Promise<number> =>
      APIClient.getFile({
        url: `/employees/hooky-folks/historical/export`,
        params: exportData,
      }),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/employees/export',
        params: exportData,
      }),
    exportEmployeeActiveButNotPresent: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/employees/active-but-not-present/export',
        params: exportData,
      }),
    exportEmployeeTerminated: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/employees/terminated/export',
        params: exportData,
      }),
    bulkSms: (data: ISendSmsToEmployees) =>
      APIClient.post('/employee/bulk-sms', data),
  },
  Department: {
    // create a new sdepartmentite
    create: (params: ICreateDepartmentRequest): Promise<IDepartmentModel> =>
      APIClient.post(`/departments`, params),
    // get department list
    list: (filters?: IFilter): Promise<IDepartmentModel[]> =>
      APIClient.get(`/departments`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/departments/count`, filter),
    // delete departments
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/departments`, where),
    // bulk update departments
    bulkUpdate: (departments: IDepartmentModel[]): Promise<void> =>
      APIClient.patch(`/departments`, departments),
    // bulk update departments
    updateDepartmentsDesiredEmployeesNumber: (
      departments: Pick<IDepartmentModel, 'id' | 'desiredEmployeesNumber'>[],
    ): Promise<void> =>
      APIClient.patch(`/departments/desired-employees-number`, departments),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/departments/export',
        params: exportData,
      }),
  },
  Logtime: {
    // check punches intersection
    checkIntersection: (
      params: IPunchIntersectionRequest[],
    ): Promise<IPunchIntersectionResponse> =>
      APIClient.post(`logtimes/intersection`, params),
    // create a new logtime
    create: (params: ICreateLogtimeRequest): Promise<ILogtimeModel> =>
      APIClient.post(apiEndpoints.LOGTIMES, params),
    // get logtime list
    list: (filters?: IFilter): Promise<ILogtimeModel[]> =>
      APIClient.get(apiEndpoints.LOGTIMES, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(apiEndpoints.LOGTIMES_COUNT, filter),
    // get logtime approved list
    listApproved: (filters?: IFilter): Promise<ILogtimeModel[]> =>
      APIClient.get(apiEndpoints.LOGTIMES_APPROVED, filters),
    // get count
    countApproved: (filter?: IFilter): Promise<number> =>
      APIClient.get(apiEndpoints.LOGTIMES_APPROVED_COUNT, filter),
    // get logtime unapproved list
    listUnapproved: (filters?: IFilter): Promise<ILogtimeModel[]> =>
      APIClient.get(apiEndpoints.LOGTIMES_UNAPPROVED, filters),
    // get count
    countUnapproved: (filter?: IFilter): Promise<number> =>
      APIClient.get(apiEndpoints.LOGTIMES_UNAPPROVED_COUNT, filter),
    // get missed list
    listMissed: (filters?: IFilter): Promise<ILogtimeModel[]> =>
      APIClient.get(apiEndpoints.LOGTIMES_MISSED, filters),
    // get missed count
    countMissed: (filter?: IFilter): Promise<number> =>
      APIClient.get(apiEndpoints.LOGTIMES_MISSED_COUNT, filter),
    supervisorViewList: (
      filter?: IFilter,
    ): Promise<ILogtimeSupervisorViewModel[]> =>
      APIClient.get(apiEndpoints.LOGTIMES_SUPERVISOR_VIEW, filter),
    // delete logtimes
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(apiEndpoints.LOGTIMES, where),
    // bulk create logtimes
    bulkCreate: (logtimesBulk: ICreateLogtimeRequest[]): Promise<void> =>
      APIClient.post(apiEndpoints.LOGTIMES_BULK, logtimesBulk),
    // bulk update logtimes
    bulkUpdate: (logtimes: ILogtimeModel[]): Promise<void> =>
      APIClient.patch(apiEndpoints.LOGTIMES, logtimes),
    // bulk moving employees to different department
    moveToDepartment: (data: MoveToDepartmentRequestType[]): Promise<void> =>
      APIClient.post(`/logtimes/move-to-department`, data),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/logtimes/export',
        params: exportData,
      }),
    exportApproved: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/logtimes/approved/export',
        params: exportData,
      }),
    exportUnapproved: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/logtimes/unapproved/export',
        params: exportData,
      }),
    exportMissed: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/logtimes/missed/export',
        params: exportData,
      }),
    exportSupervisorView: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/logtimes/export/supervisor-view',
        params: exportData,
      }),
    moveToMissed: (ids: IdsArray) => APIClient.patch(`/logtimes/missed`, ids),
  },
  StaffingProvider: {
    // create a new staffingProvider
    create: (
      params: ICreateStaffingProviderRequest,
    ): Promise<IStaffingProviderModel> =>
      APIClient.post(`/staffing-providers`, params),
    // get staffingProvider list
    list: (filters?: IFilter): Promise<IStaffingProviderModel[]> =>
      APIClient.get(`/staffing-providers`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/staffing-providers/count`, filter),
    // delete staffingProviders
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/staffing-providers`, where),
    // bulk update staffingProviders
    bulkUpdate: (staffingProviders: IStaffingProviderModel[]): Promise<void> =>
      APIClient.patch(`/staffing-providers`, staffingProviders),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/staffing-providers/export',
        params: exportData,
      }),
  },
  Skill: {
    // create a new skill
    create: (params: ICreateSkillRequest): Promise<ISkillModel> =>
      APIClient.post(`/skills`, params),
    // get skill list
    list: (filters?: IFilter): Promise<ISkillModel[]> =>
      APIClient.get(`/skills`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/skills/count`, filter),
    // delete skills
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/skills`, where),
    // bulk update skills
    bulkUpdate: (skills: ISkillModel[]): Promise<void> =>
      APIClient.patch(`/skills`, skills),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/skills/export',
        params: exportData,
      }),
  },
  Role: {
    // create a new role
    create: (params: ICreateRoleRequest): Promise<IRoleModel> =>
      APIClient.post(`/roles`, params),
    // get role list
    list: (filters?: IFilter): Promise<IRoleModel[]> =>
      APIClient.get(`/roles`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/roles/count`, filter),
    // bulk update roles
    bulkUpdate: (roles: IRoleModel[]): Promise<void> =>
      APIClient.patch(`/roles`, roles),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/roles/export',
        params: exportData,
      }),
  },
  TerminationReason: {
    // create a new terminationReason
    create: (
      params: ICreateTerminationReasonRequest,
    ): Promise<ITerminationReasonModel> =>
      APIClient.post(`/termination-reasons`, params),
    // get terminationReason list
    list: (filters?: IFilter): Promise<ITerminationReasonModel[]> =>
      APIClient.get(`/termination-reasons`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/termination-reasons/count`, filter),
    // delete terminationReasons
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/termination-reasons`, where),
    // bulk update terminationReasons
    bulkUpdate: (
      terminationReasons: ITerminationReasonModel[],
    ): Promise<void> =>
      APIClient.patch(`/termination-reasons`, terminationReasons),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/termination-reasons/export',
        params: exportData,
      }),
  },
  EmployeeSkill: {
    // create a new employeeSkill
    create: (
      params: ICreateEmployeeSkillRequest,
    ): Promise<IEmployeeSkillModel> =>
      APIClient.post(`/employee-skills`, params),
    // get employeeSkill list
    list: (filters?: IFilter): Promise<IEmployeeSkillModel[]> =>
      APIClient.get(`/employee-skills`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/employee-skills/count`, filter),
    // delete employeeSkills
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/employee-skills`, where),
    // bulk update employeeSkills
    bulkUpdate: (employeeSkills: IEmployeeSkillModel[]): Promise<void> =>
      APIClient.patch(`/employee-skills`, employeeSkills),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/employee-skills/export',
        params: exportData,
      }),
    // create a new attachment
    createAttachment: (
      params: ICreateEmployeeSkillAttachment,
    ): Promise<IEmployeeSkillAttachmentModel> =>
      APIClient.post(`/employee-skill-attachments`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    // delete attachment
    deleteAttachment: (
      where?: IWhere,
    ): Promise<IEmployeeSkillAttachmentModel> =>
      APIClient.delete(`/employee-skill-attachments`, where),
  },
  Workdays: {
    // get workdays list
    list: (filters?: IFilter): Promise<IWorkdaysModel[]> =>
      APIClient.get(`/workdays`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/workdays/count`, filter),
    // bulk update workdays
    bulkUpdate: (workdays: IWorkdaysModel[]): Promise<void> =>
      APIClient.patch(`/workdays`, workdays),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/workdays/export',
        params: exportData,
      }),
  },
  SupervisorView: {
    // get logged in employees list
    loggedIn: (): Promise<ILoggedInModel[]> =>
      APIClient.get(`/logtimes/logged-in-list`),
  },
  IssueType: {
    // create a new issueType
    create: (params: ICreateIssueType): Promise<IIssueTypeModel> =>
      APIClient.post(`/issue-types`, params),
    // get issueType list
    list: (filters?: IFilter): Promise<IIssueTypeModel[]> =>
      APIClient.get(`/issue-types`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/issue-types/count`, where),
    // delete issueTypes
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/issue-types`, where),
    // bulk update issueTypes
    bulkUpdate: (issueTypes: IIssueTypeModel[]): Promise<void> =>
      APIClient.patch(`/issue-types`, issueTypes),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/issue-types/export',
        params: exportData,
      }),
  },
  KnowledgeSetup: {
    list: (
      filter?: IFilter,
      cursor?: string,
      direction?: IWikiPaginationCursorDirection,
    ) => APIClient.get('/knowledge/pages/list', { filter, cursor, direction }),
    getById: (pageId: string) => APIClient.get(`/knowledge/pages/${pageId}`),
    getByType: (pageType: WikiPageTypeEnum) =>
      APIClient.get(`/knowledge/pages/public/${pageType}`),
    create: (data: IWikiPage) => APIClient.post('/knowledge/pages', data),
    update: (pageId: string, data: UpdateWikiForm) =>
      APIClient.patch(`/knowledge/pages/${pageId}`, data),
    delete: (pageId: string | number) =>
      APIClient.delete(`/knowledge/pages/${pageId}`),
  },
  Media: {
    send: (file: File) =>
      APIClient.post(
        `/media`,
        { file },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      ),
  },
  Issue: {
    // create a new issue
    create: (params: ICreateIssue): Promise<IIssueModel> =>
      APIClient.post(`/issues`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    // get issue list
    list: (filters?: IFilter): Promise<IIssueModel[]> =>
      APIClient.get(`/issues`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/issues/count`, where),
    // delete issues
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/issues`, where),
    // bulk update issues
    bulkUpdate: (issues: IIssueModel[]): Promise<void> =>
      APIClient.patch(`/issues`, issues, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    // downtime chart data
    getDowntimeChartData: (
      filters?: IFilter,
    ): Promise<IDowntimeChartPointData[]> =>
      APIClient.get(`/issues/charts/downtime`, filters),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/issues/export',
        params: exportData,
      }),
  },
  IssueTracker: {
    create: (
      params: ICreateIssueInIssueTracker,
    ): Promise<IIssueTrackerIssueModel> =>
      APIClient.post(`/issue-tracker`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    list: (filters?: IFilter): Promise<IIssueTrackerIssueModel[]> =>
      APIClient.get(`/issue-tracker`, filters),
    byId: (id: string): Promise<IIssueTrackerIssueResponse> =>
      APIClient.get(`/issue-tracker/${id}`),
    update: (
      issueId: string,
      body: IUpdateIssueInIssueTracker,
    ): Promise<void> =>
      APIClient.patch(`/issue-tracker/${issueId}`, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    addComment: (issueId: string, comment: string) =>
      APIClient.post(`/issue-tracker/${issueId}/comments`, { comment }),
    priorities: (): Promise<IIssueTrackerPrioritiesResponse> =>
      APIClient.get(`/issue-tracker/priorities`),
    getAttachmentContent: (id: string) =>
      APIClient.getFile({
        url: `/issue-tracker/attachment/content?attachmentId=${id}`,
      }),
  },
  PointCollection: {
    upsert: (pointCollections: IPointCollectionModel[]): Promise<void> =>
      APIClient.patch(`/point-collections`, pointCollections),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/point-collections/export',
        params: exportData,
      }),
  },
  PointType: {
    // create a new pointType
    create: (params: ICreatePointType): Promise<IPointTypeModel> =>
      APIClient.post(`/point-types`, params),
    // get pointType list
    list: (filters?: IFilter): Promise<IPointTypeModel[]> =>
      APIClient.get(`/point-types`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/point-types/count`, where),
    // delete pointTypes
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/point-types`, where),
    // bulk update pointTypes
    bulkUpdate: (pointTypes: IPointTypeModel[]): Promise<void> =>
      APIClient.patch(`/point-types`, pointTypes),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/point-types/export',
        params: exportData,
      }),
  },
  Point: {
    // create a new point
    create: (params: ICreatePoint): Promise<IPointModel> =>
      APIClient.post(apiEndpoints.POINTS, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    // get point list
    list: (filters?: IFilter): Promise<IPointModel[]> =>
      APIClient.get(apiEndpoints.POINTS, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(apiEndpoints.POINTS_COUNT, where),
    // delete points
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(apiEndpoints.POINTS, where),
    remove: (data: Array<IRemovePoint>): Promise<void> =>
      APIClient.patch(apiEndpoints.POINTS_REMOVE, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    // bulk update points
    bulkUpdate: (points: IPointModel[]): Promise<void> =>
      APIClient.patch(apiEndpoints.POINTS, points, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/points/export',
        params: exportData,
      }),
    pointsReport: (filters?: IFilter): Promise<IPointModel[]> =>
      APIClient.get(`/points/daily-earned-sum`, filters),
    pointsReportCount: (filters?: IFilter): Promise<IPointModel[]> =>
      APIClient.get(`/points/daily-earned-sum/count`, filters),
    pointsReportExport: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/points/daily-earned-sum/export',
        params: exportData,
      }),
    autoPointApprove: (points: Pick<IPointModel, 'id'>[]): Promise<void> =>
      APIClient.patch('/automatic-points-to-approve/approve', points),
    autoPointReject: (
      points: Pick<IPointModel, 'id' | 'rejectReasonId'>[],
    ): Promise<void> =>
      APIClient.patch('/automatic-points-to-approve/reject', points),
  },
  Payboard: {
    // get payboard list
    list: (filters?: IFilter): Promise<ListWithSummary<IPayboardModel>> =>
      APIClient.get(`/payboard`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/payboard/count`, where),
    // get payboard detailed list
    listDetailed: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IPayboardDetailedModel>> =>
      APIClient.get(`/payboard/weekly-detailed`, filters),
    // get count
    countDetailed: (where?: IWhere): Promise<number> =>
      APIClient.get(`/payboard/weekly-detailed/count`, where),
    listWeeklyDetailed: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IPayboardDetailedModel>> =>
      APIClient.get(`/payboard/week-employee-department`, filters),
    countWeeklyDetailed: (where?: IWhere): Promise<number> =>
      APIClient.get(`/payboard/week-employee-department/count`, where),
    exportWeeklyDetailed: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/payboard/week-employee-department/export',
        params: exportData,
      }),
    // get payboard list
    detailsView: (payload: PayboardDetailsRequest): Promise<PayboardDetails> =>
      APIClient.get(`/payboard/details-view`, payload),
    // get week count
    weekCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/payboard/week/count`, where),
    // get payboard week data
    weekView: (filters?: IFilter): Promise<ListWithSummary<PayboardWeek>> =>
      APIClient.get(`/payboard/week-view`, filters),
    dayCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/payboard/day-view/count`, where),
    dayView: (filters?: IFilter): Promise<ListWithSummary<PayboardDay>> =>
      APIClient.get(`/payboard/day-view`, filters),
    // get new week count
    newWeekCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/payboard/general/count`, where),
    // get new payboard week data
    newWeekView: (filters?: IFilter): Promise<ListWithSummary<PayboardWeek>> =>
      APIClient.get(`/payboard/general`, filters),
    // get daily hours report data count
    dailyHoursSummaryReportCount: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get(`/payboard/daily-hours-summary-report/count`, where),
    dailyHoursSummaryReport: (
      filters?: IFilter,
    ): Promise<IDailyHoursReportItem[]> =>
      APIClient.get(`/payboard/daily-hours-summary-report`, filters),
    dailyHoursDashboardReport: (
      filters?: IFilter,
    ): Promise<IDailyHoursReportItem[]> =>
      APIClient.get(`/payboard/daily-hours-summary-dashboard-report`, filters),
    dailyHoursDashboardInReport: (
      filters?: IFilter,
    ): Promise<IDailyHoursReportItem[]> =>
      APIClient.get(`/payboard/daily-hours-dashboard-in-report`, filters),
    dailyHoursDashboardInReportCount: (
      filters?: IFilter,
    ): Promise<IDailyHoursReportItem[]> =>
      APIClient.get(`/payboard/daily-hours-dashboard-in-report/count`, filters),
    // get costs for sum by department
    costSumByDepartment: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IPayboardCostByDepartment>> =>
      APIClient.get(`/payboard/costs/sum-by-department`, filters),
    // get costs for sum by day
    costsSumByDay: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IPayboardCostByDay>> =>
      APIClient.get(`/payboard/costs/sum-by-day`, filters),
    // get costs for sum by day and department
    costsSumByDayAndDepartment: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IPayboardCostByDayAndDepartment>> =>
      APIClient.get(`/payboard/costs/sum-by-day-and-department`, filters),
    export: (exportData: IExportParams) =>
      APIClient.getFile({ url: '/payboard/export', params: exportData }),
    exportDetailed: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/payboard/weekly-detailed/export',
        params: exportData,
      }),
    exportWeek: (exportData: IExportParams) =>
      APIClient.getFile({ url: '/payboard/export/week', params: exportData }),
    exportDay: (exportData: IExportParams) =>
      APIClient.getFile({ url: '/payboard/export/day', params: exportData }),
    exportNewWeek: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/payboard/export/general',
        params: exportData,
      }),
    exportDailyHours: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/payboard/export/daily-hours',
        params: exportData,
      }),

    // Summary by work week
    summaryByWorkWeek: (
      filters: IFilter,
    ): Promise<PayboardSummaryByWorkWeekData> =>
      APIClient.get('/payboard/daily-summary-by-work-week', filters),

    // Summary by work week and supervisor
    summaryByWorkWeekAndSupervisor: (
      filters: IFilter,
    ): Promise<PayboardSummaryByWorkWeekAndSupervisorData> =>
      APIClient.get(
        '/payboard/daily-summary-by-work-week-and-supervisor',
        filters,
      ),
  },
  FilledAssociates: {
    // get filledAssociates list
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IFilledAssociatesModel>> =>
      APIClient.get(`/sites/filled-associates`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/sites/filled-associates/count`, where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/sites/filled-associates/export',
        params: exportData,
      }),
  },
  CostPlusWeekly: {
    // get cost plus weekly list
    list: (filters?: IFilter): Promise<ListWithSummary<ICostPlusWeeklyModel>> =>
      APIClient.get(`/cost-plus-weekly`, filters),
    // get cost plus weekly count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/cost-plus-weekly/count`, where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cost-plus-weekly/export',
        params: exportData,
      }),
  },
  Turnover: {
    // get turnover added list
    addedList: (
      filters?: IFilter,
    ): Promise<ListWithSummary<ITurnoverAddedModel>> =>
      APIClient.get(`/turnover/added`, filters),
    // get turnover added list count
    addedCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/turnover/added/count`, where),
    // get turnover lost list
    lostList: (
      filters?: IFilter,
    ): Promise<ListWithSummary<ITurnoverLostModel>> =>
      APIClient.get(`/turnover/lost`, filters),
    // get turnover lost list count
    lostCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/turnover/lost/count`, where),
    // get turnover active list
    activeList: (
      filters?: IFilter,
    ): Promise<ListWithSummary<ITurnoverActiveModel>> =>
      APIClient.get(`/turnover/active`, filters),
    // get turnover active list count
    activeCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/turnover/active/count`, where),
    // get turnover terminated list
    terminatedList: (
      filters?: IFilter,
    ): Promise<ListWithSummary<ITurnoverTerminatedModel>> =>
      APIClient.get(`/turnover/terminated`, filters),
    // get turnover terminated list count
    terminatedCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/turnover/terminated/count`, where),
    export: (type: TurnoverType, exportData: IExportParams) =>
      APIClient.getFile({
        url: `/turnover/export/${type}`,
        params: exportData,
      }),
  },
  Metatag: {
    // create a new metatag
    create: (params: ICreateMetatagRequest): Promise<IMetatagModel> =>
      APIClient.post(`/metatags`, params),
    // get metatag list
    list: (filters?: IFilter): Promise<IMetatagModel[]> =>
      APIClient.get(`/metatags`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/metatags/count`, filter),
    // delete metatags
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/metatags`, where),
    // bulk update metatags
    bulkUpdate: (metatags: IMetatagModel[]): Promise<void> =>
      APIClient.patch(`/metatags`, metatags),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/metatags/export',
        params: exportData,
      }),
  },
  Resources: {
    // get resources list
    list: (): Promise<Resource[]> =>
      APIClient.get(`/resources/get-api-endpoints`),
  },
  Standard: {
    // create a new standard
    create: (params: ICreateStandardRequest): Promise<IStandardModel> =>
      APIClient.post(`/standards`, params),
    // get standard list
    list: (siteId: number, filters?: IFilter): Promise<IStandardWithMeta> =>
      APIClient.get(`/standards/${siteId}`, filters),
    // delete standards
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/standards`, where),
    // bulk update standards
    bulkUpdate: (standards: IStandardModel[]): Promise<void> =>
      APIClient.patch(`/standards`, standards),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/standards/export',
        params: exportData,
      }),
  },
  IpWhitelist: {
    create: (
      params: IIpWhitelistCreateRequestData,
    ): Promise<IIpWhitelistModel> => APIClient.post(`/ip-white-list`, params),
    delete: (query: string): Promise<void> =>
      APIClient.delete(`/ip-white-list?${query}`),
    bulkUpdate: (standards: { items: IIpWhitelistModel[] }): Promise<void> =>
      APIClient.patch(`/ip-white-list`, standards),
  },
  Pricing: {
    // create a new pricing
    createSingle: (
      params: ICreatePricingSingleRequest,
    ): Promise<IPricingModel> => APIClient.post(`/pricing`, params),
    createRange: (
      params: ICreatePricingRangeApiRequest,
    ): Promise<IPricingModel[]> => APIClient.post(`/pricing/range`, params),
    // get pricing list
    list: (siteId: number, filters?: IFilter): Promise<IPricingWithMeta> =>
      APIClient.get(`/pricing/${siteId}`, filters),
    // delete pricing
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/pricing`, where),
    deleteRange: (siteId: number, where?: IWhere): Promise<void> =>
      APIClient.delete(`/pricing/${siteId}/range`, { where: where }),
    // bulk update pricing
    bulkUpdate: (pricing: IPricingModel[]): Promise<void> =>
      APIClient.patch(`/pricing`, pricing),
    updateRange: (pricing: IUpdatePricingRangeApiRequest): Promise<void> =>
      APIClient.patch(`/pricing/range`, pricing),
    getSettings: (filters?: IFilter): Promise<IPricingSettings> =>
      APIClient.get(`/pricing-settings`, filters),
    createSettings: (data: AnyObject): Promise<void> =>
      APIClient.post(`/pricing-settings`, data),
    updateSettings: (data: AnyObject): Promise<void> =>
      APIClient.patch(`/pricing-settings`, data),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/pricing/export',
        params: exportData,
      }),
  },
  Kpi: {
    getData: (filters?: IFilter): Promise<IKpi[]> =>
      APIClient.get(paths.KPI, filters),
    getDataTable: (
      filters: IFilter,
      basis: KpiBasis,
      start: number,
      end: number,
    ): Promise<AnyObject[]> =>
      APIClient.get(paths.KPI_TABLE, {
        filter: filters.filter,
        basis,
        start,
        end,
      }),
    getDataReport: (
      filters: IFilter,
      basis: KpiBasis,
      start: number,
      end: number,
    ): Promise<AnyObject[]> =>
      APIClient.get(paths.KPI_REPORT, {
        filter: filters.filter,
        basis,
        start,
        end,
      }),
    createData: (siteId: number, data: IKpi): Promise<IKpi> =>
      APIClient.post(paths.KPI, data),
    updateData: (data: IKpi): Promise<IKpi> => APIClient.patch(paths.KPI, data),
    getSettings: (filters?: IFilter): Promise<IKpiSettings> =>
      APIClient.get(paths.KPI_SETTINGS, filters),
    createSettings: (data: AnyObject): Promise<void> =>
      APIClient.post(paths.KPI_SETTINGS, data),
    createSettingRecord: (data: AnyObject): Promise<void> =>
      APIClient.post(paths.KPI_SETTING_RECORD, data),
    updateSettings: (data: AnyObject): Promise<void> =>
      APIClient.patch(paths.KPI_SETTINGS, data),
  },
  Baseline: {
    // cpu based
    createManyCpuBased: (
      params: ICreateCpuBasedBaseline[],
    ): Promise<ICpuBasedBaselineModel> =>
      APIClient.post('/baselines/cpu-based', params),
    listCpuBased: (
      filters?: IFilter,
    ): Promise<ICpuBasedBaselineWithRelations> =>
      APIClient.get('/baseline/cpu-based', filters),
    countCpuBased: (where?: IWhere): Promise<number> =>
      APIClient.get('/baseline/cpu-based/count', where),
    deleteCpuBased: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/baseline/cpu-based`, where),
    bulkUpdateCpuBased: (baselines: ICpuBasedBaselineModel[]): Promise<void> =>
      APIClient.patch(`/baseline/cpu-based`, baselines),
    exportCpuBased: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/baseline/cpu-based/export',
        params: exportData,
      }),
    // area based
    createAreaBased: (
      params: ICreateAreaBasedBaseline,
    ): Promise<IAreaBasedBaselineModel> =>
      APIClient.post('/baseline/area-based', params),
    listAreaBased: (
      filters?: IFilter,
    ): Promise<IAreaBasedBaselineWithRelations> =>
      APIClient.get('/baseline/area-based', filters),
    countAreaBased: (where?: IWhere): Promise<number> =>
      APIClient.get('/baseline/area-based/count', where),
    deleteAreaBased: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/baseline/area-based`, where),
    bulkUpdateAreaBased: (
      baselines: IAreaBasedBaselineModel[],
    ): Promise<void> => APIClient.patch(`/baseline/area-based`, baselines),
    exportAreaBased: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/baseline/area-based/export',
        params: exportData,
      }),
    // just general baseline
    create: (params: ICreateBaselineRequest): Promise<IBaselineModel> =>
      APIClient.post(`/baseline`, params),
    list: (siteId: number, filter?: IFilter): Promise<IBaselineWithMeta> =>
      APIClient.get(`/baseline/${siteId}`, filter),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/baseline`, where),
    bulkUpdate: (baselineItems: IBaselineModel[]): Promise<void> =>
      APIClient.patch(`/baseline`, baselineItems),
    export: (exportData: IExportParams) =>
      APIClient.getFile({ url: '/baseline/export', params: exportData }),
  },
  Notification: {
    // get notifications
    list: (filters?: IFilter): Promise<DataWithCount<INotificationModel>> =>
      APIClient.get('/notifications', filters),
    // change notification status (read/unread)
    changeNotificationStatus: (params: INotificationData): Promise<void> =>
      APIClient.patch('/notifications/status', params),
    changeAllNotificationsStatus: (
      params: Pick<INotificationModel, 'read'>,
    ): Promise<void> =>
      APIClient.patch('/notifications/status/all-of-user', params),
    // change notification status (read/unread)
    deleteNotification: (id: number): Promise<void> =>
      APIClient.delete(`/notification/${id}`),
    deleteAllNotifications: (): Promise<void> =>
      APIClient.delete('/notifications/all-of-user'),
  },
  RecruitingDashboardSummary: {
    // get recruiting dashboard summary list
    list: (filters?: IFilter): Promise<IRecruitingDashboardSummaryModel[]> =>
      APIClient.get(`/recruiting-dashboard/summary`, filters),
    // get recruiting dashboard summary list count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/recruiting-dashboard/summary/count`, where),
    // export recruiting dashboard summary data
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/recruiting-dashboard/export',
        params: exportData,
      }),
  },
  RecruitingDashboardDetails: {
    // get recruiting dashboard details list
    list: ({
      siteId,
      filter,
    }: IRecruitingDashboardDetailsRequest): Promise<
      IRecruitingDashboardDetailsModel[]
    > => APIClient.get(`/recruiting-dashboard/${siteId}`, filter),
    // get recruiting dashboard details count
    count: ({
      siteId,
      filter,
    }: IRecruitingDashboardDetailsRequest): Promise<number> =>
      APIClient.get(
        `/recruiting-dashboard/${siteId}/count`,
        filter?.filter?.where,
      ),
    // TODO: should be refactored
    // export recruiting dashboard details data
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/recruiting-dashboard/export',
        params: exportData,
      }),
  },
  OpenPosition: {
    // create a new open position
    create: (params: ICreateOpenPosition): Promise<IOpenPositionModel> =>
      APIClient.post(`/open-positions`, params),
    // delete open positions
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/open-positions`, where),
    // bulk update open positions
    bulkUpdate: (openPositions: IUpdateOpenPosition[]): Promise<void> =>
      APIClient.patch(`/open-positions`, openPositions),
    // get open positions list
    list: (filters?: IFilter): Promise<IOpenPositionModel[]> =>
      APIClient.get(`/open-positions`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/open-positions/count`, filter),
  },
  Applicant: {
    // create a new applicant
    create: (params: ICreateApplicant): Promise<IApplicantModel> =>
      APIClient.post(`/applicants`, params),
    // delete applicants
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/applicants`, where),
    // bulk update applicants
    bulkUpdate: (applicants: IUpdateApplicant[]): Promise<void> =>
      APIClient.patch(`/applicants`, applicants),
    // get applicants list
    list: (filters?: IFilter): Promise<IApplicantModel[]> =>
      APIClient.get(`/applicants`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/applicants/count`, filter),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/applicants/export',
        params: exportData,
      }),
  },
  Policy: {
    // create a new policy
    create: (params: ICreatePolicyRequest): Promise<IPolicyModel> =>
      APIClient.post(`/policies`, params),
    // create a new policy by page
    createByPage: (
      params: ICreatePolicyByPageRequest[],
    ): Promise<IPolicyModel[]> => APIClient.post(`/policies/by-page`, params),
    createByOperation: (
      params: ICreatePolicyByOperationRequest[],
    ): Promise<IPolicyModel[]> =>
      APIClient.post(`/policies/by-operation`, params),
    // get policy list
    list: (filters?: IFilter): Promise<IPolicyModel[]> =>
      APIClient.get(`/policies`, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/policies/count`, filter),
    // delete policys
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/policies`, where),
    // bulk update policys
    bulkUpdate: (policys: IUpdatePolicy[]): Promise<void> =>
      APIClient.patch(`/policies`, policys),
  },
  SoftDelete: {
    // get related models to be deleted
    getRelations: (modelName: string): Promise<string[]> =>
      APIClient.get(`/soft-delete/get-relations/${modelName}`),
  },
  IncentivePrice: {
    create: (data: IIncentivePriceModel): Promise<IIncentivePriceModel> =>
      APIClient.post(`/incentive-prices`, data),
    listWithMeta: (filters?: IFilter): Promise<IIncentivePriceModel[]> =>
      APIClient.get(`/incentive-prices/with-meta`, filters),
    bulkUpdate: (data: IIncentivePriceUpdateRequest): Promise<void> =>
      APIClient.patch(`/incentive-prices`, data),
    getSettings: (filters?: IFilter): Promise<IIncentivePricingSettings> =>
      APIClient.get(`/incentive-price-settings`, filters),
    createSettings: (data: AnyObject): Promise<void> =>
      APIClient.post(`/incentive-price-settings`, data),
    updateSettings: (data: AnyObject): Promise<void> =>
      APIClient.patch(`/incentive-price-settings`, data),
  },
  ShiftAutomaticPointsSettings: {
    getSettings: (filters?: IFilter): Promise<ShiftAutomaticPointsSettings> =>
      APIClient.get(`/shift-automatic-points-settings`, filters),
    createSettings: (data: AnyObject): Promise<void> =>
      APIClient.post(`/shift-automatic-points-settings`, data),
    updateSettings: (data: AnyObject): Promise<void> =>
      APIClient.patch(`/shift-automatic-points-settings`, data),
  },
  Incentive: {
    create: (where: IIncentiveCreateRequest): Promise<void> =>
      APIClient.post(
        `/incentive/queue`,
        {},
        {
          params: {
            filter: { where },
          },
        },
      ),
    approve: (ids: IdsArray): Promise<void> =>
      APIClient.patch(`/incentive/approve`, ids),
    unapprove: (ids: IdsArray): Promise<void> =>
      APIClient.patch(`/incentive/unapprove`, ids),
    updateTotalPayment: (data: IUpdateTotalPayment): Promise<void> =>
      APIClient.patch(`/incentive/total-payment`, data),
  },
  Bom: {
    // create a new bom
    create: (params: ICreateBom): Promise<IBomModel> =>
      APIClient.post(`/boms`, params),
    // get bom list
    list: (filters?: IFilter): Promise<IBomModel[]> =>
      APIClient.get(`/boms`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/boms/count`, where),
    // delete boms
    delete: (where?: IWhere): Promise<void> => APIClient.delete(`/boms`, where),
    // bulk update boms
    bulkUpdate: (boms: IBomModel[]): Promise<void> =>
      APIClient.patch(`/boms`, boms),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/boms/export',
        params: exportData,
      }),
  },
  OptiturnCredentials: {
    // create a new optiturnCredentials
    create: (
      params: ICreateOptiturnCredentials,
    ): Promise<IOptiturnCredentialsModel> =>
      APIClient.post(`/optiturn-credentials`, params),
    // get optiturnCredentials list
    list: (filters?: IFilter): Promise<IOptiturnCredentialsModel[]> =>
      APIClient.get(`/optiturn-credentials`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/optiturn-credentials/count`, where),
    // delete optiturnCredentials
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/optiturn-credentials`, where),
    // bulk update optiturnCredentials
    bulkUpdate: (
      optiturnCredentials: IOptiturnCredentialsModel[],
    ): Promise<void> =>
      APIClient.patch(`/optiturn-credentials`, optiturnCredentials),
  },
  SiteImportingSettings: {
    // create a new siteImportingSetting
    create: (
      params: ICreateSiteImportingSettings,
    ): Promise<ISiteImportingSettingsModel> =>
      APIClient.post(`/site-importing-settings`, params),
    // get siteImportingSetting list
    list: (filters?: IFilter): Promise<ISiteImportingSettingsModel[]> =>
      APIClient.get(`/site-importing-settings`, filters),
    // get client keys list
    clientKeys: (): Promise<ClientKeys> =>
      APIClient.get(`/site-importing-settings/client-keys`),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/site-importing-settings/count`, where),
    // delete siteImportingSettings
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/site-importing-settings`, where),
    // bulk update siteImportingSettings
    bulkUpdate: (
      siteImportingSettings: ISiteImportingSettingsModel[],
    ): Promise<void> =>
      APIClient.patch(`/site-importing-settings`, siteImportingSettings),
  },
  SiteTaktExportingSettings: {
    create: (
      params: ICreateSiteTaktExportingSettings,
    ): Promise<ISiteTaktExportingSettingsModel> =>
      APIClient.post(`/takt-site-exporting-settings`, params),
    list: (filter?: IFilter): Promise<ISiteTaktExportingSettingsModel[]> =>
      APIClient.get(`/takt-site-exporting-settings`, filter),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/takt-site-exporting-settings/count`, where),
    bulkUpdate: (
      siteTaktExportingSettings: ISiteTaktExportingSettingsModel[],
    ): Promise<void> =>
      APIClient.patch(
        `/takt-site-exporting-settings`,
        siteTaktExportingSettings,
      ),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/takt-site-exporting-settings`, where),
  },
  SiteTaktExporting: {
    exportDailyPunches: (params: IExportDailyPunches): Promise<void> =>
      APIClient.post(`/takt-export-daily-punches`, params),
    exportRangePunches: (params: IExportRangePunches): Promise<void> =>
      APIClient.post(`/takt-export-range-punches`, params),
  },
  CostPlusSettings: {
    // create a new costPlusSetting
    create: (
      params: ICreateCostPlusSettings,
    ): Promise<ICostPlusSettingsModel> =>
      APIClient.post(`/cost-plus-settings`, params),
    // get costPlusSetting list
    list: (filters?: IFilter): Promise<ICostPlusSettingsModel[]> =>
      APIClient.get(`/cost-plus-settings`, filters),
    // get client keys list
    types: (): Promise<CostPlusTypes> =>
      APIClient.get(`/cost-plus-settings/types`),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/cost-plus-settings/count`, where),
    // delete costPlusSettings
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/cost-plus-settings`, where),
    // bulk update costPlusSettings
    bulkUpdate: (costPlusSettings: ICostPlusSettingsModel[]): Promise<void> =>
      APIClient.patch(`/cost-plus-settings`, costPlusSettings),
  },
  SiteBillingDetails: {
    create: (
      params: ICreateSiteBillingDetails,
    ): Promise<ISiteBillingDetailsModel> =>
      APIClient.post(`/site-billing-details`, params),
    createBulk: (
      params: ICreateSiteBillingDetails[],
    ): Promise<ISiteBillingDetailsModel> =>
      APIClient.post(`/site-billings-details`, params),
    list: (filters?: IFilter): Promise<ISiteBillingDetailsModel[]> =>
      APIClient.get(`/site-billing-details`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/site-billing-details/count`, where),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/site-billing-details`, where),
    bulkUpdate: (
      siteBillingDetails: ISiteBillingDetailsModel[],
    ): Promise<void> =>
      APIClient.patch(`/site-billing-details`, siteBillingDetails),
  },
  NgroupBillingDetails: {
    create: (
      params: ICreateNgroupBillingDetails,
    ): Promise<INgroupBillingDetailsModel> =>
      APIClient.post(`/ngroup-billing-details`, params),
    createBulk: (
      params: ICreateNgroupBillingDetails[],
    ): Promise<INgroupBillingDetailsModel> =>
      APIClient.post(`/ngroup-billings-details`, params),
    list: (filters?: IFilter): Promise<INgroupBillingDetailsModel[]> =>
      APIClient.get(`/ngroup-billing-details`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/ngroup-billing-details/count`, where),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/ngroup-billing-details`, where),
    bulkUpdate: (
      ngroupBillingDetails: INgroupBillingDetailsModel[],
    ): Promise<void> =>
      APIClient.patch(`/ngroup-billing-details`, ngroupBillingDetails),
  },
  Invoice: {
    create: (
      params: ICreateWeekBasedInvoice,
    ): Promise<IInvoiceModelWithRelations> =>
      APIClient.post(`/invoice`, params),
    createMonth: (
      params: ICreateMonthBasedInvoiceRequest,
    ): Promise<Array<IInvoiceModelWithRelations>> =>
      APIClient.post(`/invoice/month`, params),
    list: (filters?: IFilter): Promise<IInvoiceModelWithRelations[]> =>
      APIClient.get(`/invoice`, filters),
    monthList: (filters?: IFilter): Promise<IInvoiceModelWithRelations[]> =>
      APIClient.get(`/invoice/month`, filters),
    byId: (invoiceId: number): Promise<IInvoiceDetails> =>
      APIClient.get(`/invoice/${invoiceId}`),
    monthById: (monthInvoiceId: string): Promise<IInvoiceMonthDetails> =>
      APIClient.get(`/invoice/month/${monthInvoiceId}`),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/invoice/count`, where),
    monthCount: (where?: IWhere): Promise<number> =>
      APIClient.get(`/invoice/month/count`, where),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/invoice`, where),
    bulkUpdate: (ngroupBillingDetails: IUpdateInvoice[]): Promise<void> =>
      APIClient.patch(`/invoice/bulk`, ngroupBillingDetails),
    monthBulkUpdate: (
      ngroupBillingDetails: IUpdateMonthInvoice[],
    ): Promise<void> =>
      APIClient.patch(`/invoice/month/bulk`, ngroupBillingDetails),
    freeze: (invoiceId: number): Promise<void> =>
      APIClient.patch(`/invoice/${invoiceId}/freeze`),
    freezeMonth: (monthInvoiceId: string): Promise<void> =>
      APIClient.patch(`/invoice/month/${monthInvoiceId}/freeze`),
    unfreeze: (invoiceId: number): Promise<void> =>
      APIClient.patch(`/invoice/${invoiceId}/unfreeze`),
    unfreezeMonth: (monthInvoiceId: string): Promise<void> =>
      APIClient.patch(`/invoice/month/${monthInvoiceId}/unfreeze`),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/invoice/export',
        params: exportData,
      }),
    monthExport: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/invoice/month/export',
        params: exportData,
      }),
    exportDetails: ({ id, format }: IExportInvoice) =>
      APIClient.getFile({
        url: `/invoice/${id}/export/${format}`,
      }),
    exportMonthDetails: ({ id, format }: IExportInvoice) =>
      APIClient.getFile({
        url: `/invoice/month/${id}/export/${format}`,
      }),
  },
  AdditionalFee: {
    create: (params: IAdditionalFee): Promise<IAdditionalFeeModel> =>
      APIClient.post(`/additional-fee`, params),
    list: (filters?: IFilter): Promise<IAdditionalFeeModel[]> =>
      APIClient.get(`/additional-fee`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/additional-fee/count`, where),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/additional-fee`, where),
    bulkUpdate: (costPlusSettings: IAdditionalFeeModel[]): Promise<void> =>
      APIClient.patch(`/additional-fee`, costPlusSettings),
  },
  AdditionalFeeCategory: {
    create: (
      params: IAdditionalFeeCategory,
    ): Promise<IAdditionalFeeCategoryModel> =>
      APIClient.post(`/additional-fee-category`, params),
    list: (filters?: IFilter): Promise<ICostPlusSettingsModel[]> =>
      APIClient.get(`/additional-fee-category`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/additional-fee-category/count`, where),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/additional-fee-category`, where),
    bulkUpdate: (
      additionalFeeCategories: IAdditionalFeeCategoryModel[],
    ): Promise<void> =>
      APIClient.patch(`/additional-fee-category`, additionalFeeCategories),
  },
  OtherCosts: {
    // create a new otherCosts
    create: (params: ICreateOtherCosts): Promise<IOtherCostsModel> =>
      APIClient.post(`/other-costs`, params),
    // get otherCosts list
    list: (filters?: IFilter): Promise<IOtherCostsModel[]> =>
      APIClient.get(`/other-costs`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/other-costs/count`, where),
    // delete otherCosts
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/other-costs`, where),
    // bulk update otherCosts
    bulkUpdate: (otherCosts: IOtherCostsModel[]): Promise<void> =>
      APIClient.patch(`/other-costs`, otherCosts),
  },
  FinancialTracker: {
    list: (
      filter?: IFilter,
    ): Promise<ListWithSummary<IFinancialTrackerModel>> =>
      APIClient.get('/financial-tracker', filter),
    create: (params: IFinancialTrackerCreate): Promise<void> =>
      APIClient.post('/financial-tracker', params),
    bulkUpdate: (financialTrackers: IFinancialTrackerUpdate[]): Promise<void> =>
      APIClient.patch('/financial-tracker', financialTrackers),
  },
  Favorite: {
    add: (url: string): Promise<void> =>
      APIClient.post(paths.FAVORITE, { url }),
    list: (): Promise<Array<IFavoriteModel>> => APIClient.get(paths.FAVORITE),
    delete: (id: number): Promise<void> =>
      APIClient.delete(`${paths.FAVORITE}/${id}`),
  },
  Production: {
    // create a new production
    bulkCreate: (
      params: Array<ICreateProduction>,
    ): Promise<Array<IProductionModel>> =>
      APIClient.post(`/productions`, params),
    // get production list
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get(`/productions`, filters),
    // get count
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/productions/count`, where),
    // delete productions
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/productions`, where),
    // bulk update productions
    bulkUpdate: (productions: IProductionModel[]): Promise<void> =>
      APIClient.patch(`/productions`, productions),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/productions/export',
        params: exportData,
      }),
    uploadProduction: (formData: FormData): Promise<void> =>
      APIClient.post('/import/upload', formData),
    getSettings: (filters?: IFilter): Promise<IManualProductionSettings> =>
      APIClient.get(`/production-settings`, filters),
    createSettings: (data: AnyObject): Promise<void> =>
      APIClient.post(`/production-settings`, data),
    updateSettings: (data: AnyObject): Promise<void> =>
      APIClient.patch(`/production-settings`, data),
  },
  CardinalHealthDailyReport: {
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get(`/cardinal-health/daily-report`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/cardinal-health/daily-report/count`, where),
  },
  CardinalHealthDailyUphBySite: {
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get(`/cardinal-health/daily-uph/by-site`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/cardinal-health/daily-uph/by-site/count`, where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/daily-uph/by-site/export',
        params: exportData,
      }),
  },
  CardinalHealthDailyUphBySiteAndSku: {
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get('/cardinal-health/daily-uph/by-site-and-sku', filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get('/cardinal-health/daily-uph/by-site-and-sku/count', where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/daily-uph/by-site-and-sku/export',
        params: exportData,
      }),
  },
  CardinalHealthDailyUphBySiteAndEmployee: {
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get('/cardinal-health/daily-uph/by-site-and-employee', filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        '/cardinal-health/daily-uph/by-site-and-employee/count',
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/daily-uph/by-site-and-employee/export',
        params: exportData,
      }),
  },
  CardinalHealthDailyUphBySiteSkuAndEmployee: {
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get(
        '/cardinal-health/daily-uph/by-site-sku-and-employee',
        filters,
      ),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        '/cardinal-health/daily-uph/by-site-sku-and-employee/count',
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/daily-uph/by-site-sku-and-employee/export',
        params: exportData,
      }),
  },
  CardinalHealthDailyUphBySiteAndShift: {
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get('/cardinal-health/daily-uph/by-site-and-shift', filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        '/cardinal-health/daily-uph/by-site-and-shift/count',
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/daily-uph/by-site-and-shift/export',
        params: exportData,
      }),
  },
  CardinalHealthWeeklyUphBySite: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get('/cardinal-health/weekly-uph/by-site', filters),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get('/cardinal-health/weekly-uph/by-site/count', where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/weekly-uph/by-site/export',
        params: exportData,
      }),
  },
  CardinalHealthWeeklyUphBySiteAndEmployee: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get(
        '/cardinal-health/weekly-uph/by-site-and-employee',
        filters,
      ),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get(
        '/cardinal-health/weekly-uph/by-site-and-employee/count',
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/weekly-uph/by-site-and-employee/export',
        params: exportData,
      }),
  },
  CardinalHealthWeeklyUphBySiteAndSku: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get('/cardinal-health/weekly-uph/by-site-and-sku', filters),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get('/cardinal-health/weekly-uph/by-site-and-sku/count', where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/weekly-uph/by-site-and-sku/export',
        params: exportData,
      }),
  },
  CardinalHealthWeeklyUphBySiteSkuAndEmployee: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get(
        '/cardinal-health/weekly-uph/by-site-sku-and-employee',
        filters,
      ),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get(
        '/cardinal-health/weekly-uph/by-site-sku-and-employee/count',
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/weekly-uph/by-site-sku-and-employee/export',
        params: exportData,
      }),
  },

  CardinalHealthWeeklyCpuDetailed: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionWeeklyCpuDetailedModel>> =>
      APIClient.get(
        '/cardinal-health/weekly-cpu/by-site-sku-and-employee',
        filters,
      ),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get(
        '/cardinal-health/weekly-cpu/by-site-sku-and-employee/count',
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/weekly-cpu/by-site-sku-and-employee/export',
        params: exportData,
      }),
  },
  CardinalHealthWeeklyCpu: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionWeeklyCpuModel>> =>
      APIClient.get('/cardinal-health/weekly-cpu/by-site-and-sku', filters),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get('/cardinal-health/weekly-cpu/by-site-and-sku/count', where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/weekly-cpu/by-site-and-sku/export',
        params: exportData,
      }),
  },
  CardinalHealthWeeklyUphBySiteAndShift: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get('/cardinal-health/weekly-uph/by-site-and-shift', filters),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get(
        '/cardinal-health/weekly-uph/by-site-and-shift/count',
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/cardinal-health/weekly-uph/by-site-and-shift/export',
        params: exportData,
      }),
  },
  CardinalHealthExceptionFromEmployees: {
    data: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get(
        '/cardinal-health/exceptions/production/from-employee',
        filters,
      ),
  },
  CardinalHealthExceptionFromProduction: {
    data: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get(
        '/cardinal-health/exceptions/production/from-production',
        filters,
      ),
  },
  ImportSynchronization: (data: ISyncImporting): Promise<void> =>
    APIClient.patch('/import/synchronization', data),

  // Fetch reports method
  Report: {
    list: <T>(
      reportUrl: string,
      filters?: IFilter,
      requestConfig?: AxiosRequestConfig,
    ): Promise<ListWithSummary<T>> =>
      APIClient.get(reportUrl, filters, requestConfig),
    count: (
      reportUrl: string,
      filters?: IFilter,
      requestConfig?: AxiosRequestConfig,
    ): Promise<ICountResponse> =>
      APIClient.get(`${reportUrl}/count`, filters, requestConfig),
    export: (reportUrl: string, exportData: IExportParams) =>
      APIClient.getFile({
        url: `${reportUrl}/export`,
        params: exportData,
      }),
    data: <T>(
      reportUrl: string,
      filters?: IFilter,
    ): Promise<{ count: number; data: T }> => APIClient.get(reportUrl, filters),
    dataRaw: <T>(reportUrl: string, filters?: IFilter & IQSJWT): Promise<T> =>
      APIClient.get(reportUrl, filters),
    delete: (reportUrl: string, where?: IWhere): Promise<void> =>
      APIClient.delete(reportUrl, where),
  },
  Request: {
    get: <T>(
      url: string,
      filters?: IFilter,
      requestConfig?: AxiosRequestConfig,
    ): Promise<T> => APIClient.get(url, filters, requestConfig),
  },
  WeeklyGrossMargin: {
    list: (filters: IFilter): Promise<IWeeklyGrossMargin[]> =>
      APIClient.get(`/gross-margin/weekly`, filters),
    count: (filters: IFilter): Promise<ICountResponse> =>
      APIClient.get(`/gross-margin/weekly/count`, filters),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/gross-margin/weekly/export`,
        params: exportData,
      }),
  },
  WeeklyGrossMarginDetailed: {
    list: (filters: IFilter): Promise<IGrossMargin[]> =>
      APIClient.get(`/gross-margin`, filters),
    count: (filters: IFilter): Promise<ICountResponse> =>
      APIClient.get(`/gross-margin/count`, filters),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/gross-margin/export`,
        params: exportData,
      }),
  },
  Exceptions: {
    fromProduction: (
      url: string,
      filters?: IFilter,
    ): Promise<IExceptionsReportDataResponse> => APIClient.get(url, filters),
  },

  // Optoro reports
  OptoroDailyReport: {
    list: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get('/optoro/daily-report', filters),
    count: (where?: IWhere): Promise<ICountResponse> =>
      APIClient.get('/optoro/daily-report/count', where),
  },
  OptoroExceptionFromEmployees: {
    data: (
      filters?: IFilter,
    ): Promise<ListWithSummary<IProductionDailyUphBase>> =>
      APIClient.get('/optoro/exceptions/production/from-employee', filters),
  },

  ExpeditorsDaily: {
    list: (filters?: IFilter): Promise<IProductionModel[]> =>
      APIClient.get(`/expeditors/daily-report`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/expeditors/daily-report/count`, where),
    gapChart: (filters?: IFilter): Promise<any> =>
      APIClient.get('/expeditors/gaps/chart', filters),
  },

  PerryEllisDaily: {
    gapChart: (filters?: IFilter): Promise<any> =>
      APIClient.get('/perry-ellis/gaps/chart', filters),
  },

  // Production reports
  ProductionDailyUphBySite: {
    list: (filters?: IFilter): Promise<IProductionDailyUphBySiteModel[]> =>
      APIClient.get(`/productions/reports/daily-uph/by-site`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/productions/reports/daily-uph/by-site/count`, where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/daily-uph/by-site/export`,
        params: exportData,
      }),
  },
  ProductionDailyUphBySiteAndMetatag: {
    list: (
      filters?: IFilter,
    ): Promise<IProductionDailyUphBySiteAndMetatagModel[]> =>
      APIClient.get(
        `/productions/reports/daily-uph/by-site-and-metatag`,
        filters,
      ),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        `/productions/reports/daily-uph/by-site-and-metatag/count`,
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/daily-uph/by-site-and-metatag/export`,
        params: exportData,
      }),
  },
  ProductionDailyUphBySiteAndEmployee: {
    list: (
      filters: IFilter,
    ): Promise<IProductionDailyUphBySiteAndEmployeeModel[]> =>
      APIClient.get(
        `/productions/reports/daily-uph/by-site-and-employee`,
        filters,
      ),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        `/productions/reports/daily-uph/by-site-and-employee/count`,
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/daily-uph/by-site-and-employee/export`,
        params: exportData,
      }),
  },
  ProductionDailyUphBySiteSkuAndEmployee: {
    list: (
      filters: IFilter,
    ): Promise<IProductionDailyUphBySiteSkuAndEmployeeModel[]> =>
      APIClient.get(
        `/productions/reports/daily-uph/by-site-sku-and-employee`,
        filters,
      ),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        `/productions/reports/daily-uph/by-site-sku-and-employee/count`,
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/daily-uph/by-site-sku-and-employee/export`,
        params: exportData,
      }),
  },
  ProductionWeeklyUphBySite: {
    list: (filters: IFilter): Promise<IProductionWeeklyUphBySiteModel[]> =>
      APIClient.get(`/productions/reports/weekly-uph/by-site`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(`/productions/reports/weekly-uph/by-site/count`, where),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/weekly-uph/by-site/export`,
        params: exportData,
      }),
  },
  ProductionWeeklyUphBySiteAndSku: {
    list: (
      filters: IFilter,
    ): Promise<IProductionWeeklyUphBySiteAndSkuModel[]> =>
      APIClient.get(`/productions/reports/weekly-uph/by-site-and-sku`, filters),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        `/productions/reports/weekly-uph/by-site-and-sku/count`,
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/weekly-uph/by-site-and-sku/export`,
        params: exportData,
      }),
  },
  ProductionWeeklyUphBySiteAndEmployee: {
    list: (
      filters: IFilter,
    ): Promise<IProductionWeeklyUphBySiteAndEmployeeModel[]> =>
      APIClient.get(
        `/productions/reports/weekly-uph/by-site-and-employee`,
        filters,
      ),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        `/productions/reports/weekly-uph/by-site-and-employee/count`,
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/weekly-uph/by-site-and-employee/export`,
        params: exportData,
      }),
  },
  ProductionWeeklyUphBySiteSkuAndEmployee: {
    list: (
      filters: IFilter,
    ): Promise<IProductionWeeklyUphBySiteSkuAndEmployeeModel[]> =>
      APIClient.get(
        `/productions/reports/weekly-uph/by-site-sku-and-employee`,
        filters,
      ),
    count: (where?: IWhere): Promise<number> =>
      APIClient.get(
        `/productions/reports/weekly-uph/by-site-sku-and-employee/count`,
        where,
      ),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: `/productions/reports/weekly-uph/by-site-sku-and-employee/export`,
        params: exportData,
      }),
  },
  Guide: {
    videoUrlList: (): Promise<Array<IVideoItem>> =>
      APIClient.get('/video-urls'),
  },
  Checklist: {
    list: (filters?: IFilter): Promise<IChecklistModelWithRelations[]> =>
      APIClient.get(`/checklist`, filters),
    tasks: (filters?: IFilter): Promise<IChecklistTaskModel[]> =>
      APIClient.get(`/task`, filters),
    createTask: (
      taskData: ICreateChecklistTaskData,
    ): Promise<IChecklistTaskModel> => APIClient.post('/task', taskData),
    createChecklist: (
      checklistData: ICreateChecklistData,
    ): Promise<IChecklistModel> => APIClient.post('/checklist', checklistData),
    updateTask: (taskData: IUpdateChecklistTaskData): Promise<void> =>
      APIClient.patch('/task', [taskData]),
    updateChecklist: (taskData: IUpdateChecklistData): Promise<void> =>
      APIClient.patch('/checklist', [taskData]),
    deleteTask: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/task`, where),
    deleteRecurringTask: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/recurring-task`, where),
    deleteChecklist: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/checklist`, where),
    countChecklists: (where?: IWhere): Promise<number> =>
      APIClient.get(`/checklist/count`, where),
  },
  QuestionCategory: {
    // create a new position
    create: (
      params: ICreateQuestionCategoryRequest,
    ): Promise<IQuestionCategoryModel> =>
      APIClient.post(apiEndpoints.QUESTION_CATEGORY, params),
    // get position list
    list: (filters?: IFilter): Promise<IQuestionCategoryModel[]> =>
      APIClient.get(apiEndpoints.QUESTION_CATEGORY, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(apiEndpoints.QUESTION_CATEGORY_COUNT, filter),
    // delete question-category
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(apiEndpoints.QUESTION_CATEGORY, where),
    // bulk update question-category
    bulkUpdate: (
      questionCategory: IUpdateQuestionCategoryRequest[],
    ): Promise<void> =>
      APIClient.patch(apiEndpoints.QUESTION_CATEGORY, questionCategory),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/question-category/export',
        params: exportData,
      }),
  },
  QuestionEmployee: {
    fillInQuestionnaire: (params: FormData): Promise<void> =>
      APIClient.post(`/employee-review`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    fillInOverdueQuestionnaire: (params: FormData): Promise<void> =>
      APIClient.post(`/employee-review/overdue`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    employeesReview: (filters?: IFilter): Promise<IQuestionCategoryModel[]> =>
      APIClient.get(`/employee-review`, filters),
    employeesReviewCount: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/employee-review/count`, filter),
    overdue: (filters?: IFilter): Promise<IQuestionCategoryModel[]> =>
      APIClient.get(`/question-employee/overdue`, filters),
    overdueCount: (filter?: IFilter): Promise<number> =>
      APIClient.get(`/question-employee/overdue/count`, filter),
    bulkUpdate: (params: IQuestionEmployeeModel[]): Promise<void> =>
      APIClient.patch(`/employee-review`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      }),
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/question-employee`, where),
    deleteReview: (where?: IWhere): Promise<void> =>
      APIClient.delete(`/employee-review`, where),
  },
  Question: {
    // create a new position
    create: (params: ICreateQuestionRequest): Promise<IQuestionModel> =>
      APIClient.post(apiEndpoints.QUESTIONS, params),
    // get position list
    list: (filters?: IFilter): Promise<IQuestionModel[]> =>
      APIClient.get(apiEndpoints.QUESTIONS, filters),
    // get count
    count: (filter?: IFilter): Promise<number> =>
      APIClient.get(apiEndpoints.QUESTIONS_COUNT, filter),
    // delete question
    delete: (where?: IWhere): Promise<void> =>
      APIClient.delete(apiEndpoints.QUESTIONS, where),
    // bulk update question
    bulkUpdate: (questionCategory: IUpdateQuestionRequest[]): Promise<void> =>
      APIClient.patch(apiEndpoints.QUESTIONS, questionCategory),
    export: (exportData: IExportParams) =>
      APIClient.getFile({
        url: '/question/export',
        params: exportData,
      }),
  },
  incentivePaymentSettings: {
    create: (
      params: Omit<IIncentivePaymentSettingsResponseData, 'id'>,
    ): Promise<void> =>
      APIClient.post(apiEndpoints.INCENTIVE_PAYMENT_SETTINGS, params),
    update: (params: IIncentivePaymentSettingsResponseData): Promise<void> =>
      APIClient.patch(apiEndpoints.INCENTIVE_PAYMENT_SETTINGS, params),
  },
};

export const SocketClient = {
  Notifications: (token: string) => {
    if (token) {
      return io(`${process.env.REACT_APP_API_URL}/notifications`, {
        transports: ['websocket'],
        upgrade: false,
        auth: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return null;
  },
};

export default Api;
